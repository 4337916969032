import React, { useState } from 'react';
import { db } from '../services/firebase'; // Adjust this import path to your Firebase configuration
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { isTokenValid, invalidateToken } from './GenerateToken'; // Import the isTokenValid function


const AcceptInvite = ({ onClose, currentUser }) => {
  const [token, setToken] = useState('');
  const [error, setError] = useState('');

  const handleAcceptInvite = async () => {
    console.log('Current user:', currentUser);
  
    if (!currentUser) {
      setError('You must be logged in to accept an invitation.');
      return;
    }

     // Wait for isTokenValid to complete and get the result
     const valid = await isTokenValid(token);
     if (!valid) {
       setError('Token is invalid or has expired.');
 
       // Token is either expired or already used
       return { error: "Token is invalid or has expired." };
     }
  
    console.log('Checking invitation in the database');
    console.log(token);
  
    // Check for the invitation in the database
    const invitationRef = doc(db, 'Invitations', token);
    console.log('no error', invitationRef);

    const invitationSnap = await getDoc(invitationRef);
  
    console.log('Checked');
    
    if (!invitationSnap.exists()) {
      setError('Invalid token');
      return;
    }

    
  
    const invitation = invitationSnap.data();
    console.log('Adding user to team');
    console.log('debug: ', invitation.teamId)
    console.log('UID:', currentUser.uid);
    console.log('Email:', currentUser.email);

    // Add user to team members
    const teamRef = doc(db, 'Teams', invitation.teamId);
    console.log('Got teams');

    await updateDoc(teamRef, {
      members: arrayUnion(currentUser.uid),
      invitationToken: token // Ensure this token is the one from the invitation
    });
    
    console.log('Adding team to user');

    // Also add the team to the user's 'teams' array in the Users collection
    const userRef = doc(db, 'Users', currentUser.uid);
    await updateDoc(userRef, {
      teams: arrayUnion(invitation.teamId)
    });
    
    invalidateToken(token);
    // Success feedback
    alert('You have joined the team!');
    // Clear token
    setToken('');


  };
  

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        {/* Background overlay, show modal backdrop */}
        <div className="fixed inset-0 transition-opacity" onClick={onClose}>
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        {/* Modal Panel */}
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Accept Team Invitation
                  {/* Close Button */}
                  <button
                    className="absolute top-0 right-0 pt-4 pr-4"
                    onClick={onClose}
                  >
                    <span className="text-gray-400 hover:text-gray-500">&times;</span>
                  </button>
                </h3>
                <div className="mt-2">
                  <input
                    type="text"
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    placeholder="Enter invitation token"
                    className="input-field-classes" // Replace with your input field classes
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            {/* Accept Button */}
            <button
              onClick={handleAcceptInvite}
              className="ml-3 inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-green-600 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-0 sm:w-auto sm:text-sm"
            >
              Accept Invitation
            </button>
            {/* Optional Error Message */}
            {error && <p className="error-classes">{error}</p>} {/* Replace with your error message classes */}
          </div>
        </div>
      </div>
    </div>
  );
  
  
};

export default AcceptInvite;
