import React, { useState, useEffect } from 'react';
import { db } from '../services/firebase';
import { doc, setDoc } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../services/firebase'; // Make sure to import your auth module
import { generateToken } from './GenerateToken'; // Import the token generation function

const InviteMember = ({ onClose, teamId }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [user] = useAuthState(auth); // Use the useAuthState hook to get the current user
    const [isSMTPJSLoaded, setSMTPJSLoaded] = useState(false);

    // Load the SMTP.js script once when the component mounts
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://smtpjs.com/v3/smtp.js';
        script.onload = () => {
            setSMTPJSLoaded(true);
        };
        document.head.appendChild(script);
    }, []);

    const sendInvitation = async () => {
        if (!user) {
            setError('You must be logged in to send an invitation.');
            return;
        }

        // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email is valid
    if (!emailPattern.test(email)) {
        setError('Please enter a valid email address.');
        return;
    }

    
        try {
            console.log("should create token")
            const { tokenValue, savePromise } = await generateToken(email, teamId);
            console.log(tokenValue)

            await savePromise; // Wait for the token to be saved in the database
            console.log(savePromise)

            if (isSMTPJSLoaded) {
                sendEmailWithSMTPJS(email, tokenValue);
            } else {
                setError('SMTP.js is not loaded yet.');
            }
            // Reset the email input
            setEmail('');
            setError(null);
            // Provide feedback to the user that the invitation was sent
            alert(`Invitation sent to ${email}`);
        } catch (err) {
            setError(err.message);
        }
    };
      
    const sendEmailWithSMTPJS = (email, invitationToken) => {
        window.Email.send({
            SecureToken: "c57c9762-9c41-4e36-ae79-86ee478e401f",
            To: email,
            From: "histoire.et.autre@gmail.com",
            Subject: "Invitation to Join Team",
            Body: `You have been invited to join the team. Please use the following token to accept the invitation: ${invitationToken}`,
        }).then(
            message => alert('Invitation sent successfully!'),
            error => {
                alert('Failed to send invitation.');
                console.error('SMTP.js send email error:', error);
            }
        );
    };

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                {/* Background overlay, show modal backdrop */}
                <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
                {/* Modal Panel */}
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                              <p className="modal-title">Invite a Team Member</p>
                              </h3>

                                <div className="mt-2">
                                    <form className="invite-form"></form>
                                    <input
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Invitee email"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button onClick={sendInvitation} className="ml-3 inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-green-600 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-0 sm:w-auto sm:text-sm"
            disabled={!user}>Send Invitation</button>
                {error && <p>Error: {error}</p>}
                </div>
                    {/* Close Button */}
                    <button
                    className="absolute top-0 right-0 pt-4 pr-4"
                    onClick={onClose}
                    >
                    <span className="text-gray-400 hover:text-gray-500">&times;</span>
                    </button>

            </div>
        </div>
    </div>
    );
};

export default InviteMember;
