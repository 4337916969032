import { db } from '../services/firebase';
import { getDoc, doc, setDoc, updateDoc } from 'firebase/firestore';

export const generateToken = async (invitedUserEmail, invitedTeamId) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < 20; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    const tokenValue = result;

    const tokenData = {
      invitationToken: tokenValue,
      createdAt: new Date().toISOString(),
      isValid: true,
      invitedUserEmail: invitedUserEmail, // Add the email address of the invited user
      teamId : invitedTeamId,
    };

    // Save the token to the database
    const savePromise = setDoc(doc(db, 'Invitations', tokenValue), tokenData);
    return { tokenValue, savePromise };
};

const saveToken = (token) => {
  // Simulate saving the token to a database
  // Replace this with actual database save logic
  console.log('Saving token:', token);
};

const updateTokenValidity = async (tokenValue, isValid) => {
  // Log the operation
  console.log(`Updating token validity for ${tokenValue} to ${isValid}`);
  
  // Reference the document in the 'Invitations' collection
  const tokenRef = doc(db, 'Invitations', tokenValue);
  
  // Update the 'isValid' field of the document
  try {
    await updateDoc(tokenRef, {
      isValid: isValid // Set the isValid field to the passed boolean value
    });
    console.log('Token validity updated to:', isValid);
  } catch (error) {
    // Log any errors during the update
    console.error('Error updating token validity:', error);
  }
};


export const getTokenData = async (tokenValue) => {
  const tokenRef = doc(db, 'Invitations', tokenValue);

  try {
    const tokenSnap = await getDoc(tokenRef);

    if (tokenSnap.exists()) {
      // Convert the document to JSON
      const tokenData = tokenSnap.data();

      // Return the token data
      return tokenData;
    } else {
      // Handle the case where the token does not exist
      console.log('No such token!');
      return null;
    }
  } catch (error) {
    // Handle any errors that occur during the fetch
    console.error('Error getting token data:', error);
    return null;
  }
};


export const isTokenValid = async (tokenValue) => {
  const tokenData = await getTokenData(tokenValue);
  const currentTime = Date.now();

  if (!tokenData) {
    return false;
  }

  console.log(tokenData.isValid);

  // Assuming 'createdAt' is stored as a Firestore Timestamp, you need to convert it to Date
  //const createdAt = tokenData.createdAt.toDate().getTime();
  const createdAt = tokenData.createdAt;

  if (tokenData.isValid && currentTime - createdAt < 5 * 60 * 1000) {
    return true;
  } else if (tokenData.isValid === false) {
    return false;
  } else {
    await invalidateToken(tokenValue);
    return false;
  }
};


export const invalidateToken = async (tokenValue) => {
  // Call the updateTokenValidity function with the 'isValid' argument set to false
  await updateTokenValidity(tokenValue, false);
};