import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, deleteTeamFromFirebase, quitTeamFromFirebase } from '../services/firebase';
import { signOut } from 'firebase/auth';
import { submitTransaction } from '../TransactionForm';
import TeamSelection from '../TeamSelection';
import TeamActions from '../TeamActions';
import TransactionListWrapper from '../TransactionListWrapper';
import Modals from '../Modals';
import BarChartComponent from '../Charts'; // Import the BarChart component
import { getTransactions, processRefundData } from '../RecapData';
import RefundList from '../RefundList'; // Import the new component
import UserIcon from '../UserIcon';
import settingIcon from '../assets/settingIcon.png'; // Import the settings icon

const UserContent = ({ onUserChanged, teams, currentTeam, onCreateTeam, onSwitchTeam }) => {
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [showAcceptInvite, setShowAcceptInvite] = useState(false);
  const [refreshList, setRefreshList] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [showDeleteTeamModal, setShowDeleteTeamModal] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isLastMember, setIsLastMember] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [balances, setBalances] = useState({});
  const [activeTab, setActiveTab] = useState('Transactions'); // Default to 'Transactions' tab
  const [refundBalances, setRefundBalances] = useState({});
  const [showTeamActions, setShowTeamActions] = useState(false); // State for showing TeamActions
  const [showSettings, setShowSettings] = useState(false); // This should be in your parent component
  // This effect should run once when the component mounts and whenever the `teams` or `currentTeam` changes.

  useEffect(() => {
    if (teams && teams.length > 0 && !currentTeam) {
      onSwitchTeam(teams[0].id); // Set the first team as current if none is selected
    }
    
    const currentTeamData = teams.find(team => team.id === currentTeam);
    
    if (currentTeamData) {
      setIsOwner(user && user.uid === currentTeamData.ownerId);
      setIsLastMember(currentTeamData.members && currentTeamData.members.length === 1);
    } else {
      setIsOwner(false);
      setIsLastMember(false);
    }
    
    const loadTransactions = async () => {
      const transactions = await getTransactions(currentTeam);
      const calculatedBalances = processRefundData(transactions);
      setBalances(calculatedBalances);
    };
  
    if (currentTeam) {
      loadTransactions();
    }

    const loadRefundBalances = async () => {
      const transactions = await getTransactions(currentTeam);
      const calculatedBalances = processRefundData(transactions);
      setRefundBalances(calculatedBalances);
    };

    if (currentTeam) {
      loadRefundBalances();
    }

  }, [teams, currentTeam, user, onSwitchTeam]);

  const handleShowTransactionModal = () => {
    console.log('showing transaction modal');
    setShowTransactionModal(true);
  };

  const handleCreateTeam = async (teamName) => {
    if (loading) {
      alert('You are loading');
      return;
    }

    if (!user) {
      alert('You must be logged in to create a team.');
      return;
    }

    if (teams && teams.length >= 7) {
      alert('You have reached the maximum number of teams allowed (7).');
      return;
    }

    try {
      await onCreateTeam(teamName, user.uid);
      setShowCreateTeamModal(false);
    } catch (firebaseError) {
      console.error('Failed to create team:', firebaseError);
      alert('Failed to create team. Please try again.');
    }
  };

  const handleSaveTransaction = async (transactionData) => {
    try {
      const responseData = await submitTransaction(transactionData);
      
      if (responseData) {
        setRefreshList(prev => !prev); // Toggle to trigger a refresh
      }
    } catch (error) {
      console.error('Failed to save transaction:', error);
    }
  };
  
  const handleSignOut = (e) => {
    e.preventDefault();

    signOut(auth).then(() => {
      localStorage.removeItem('user');
      window.location.reload();
    }).catch((error) => {
      console.error('Sign out error:', error);
    });
  };

  const handleDeleteTeam = async () => {
    if (!currentTeam) return;
    try {
      await deleteTeamFromFirebase(currentTeam, user.uid);
      setShowDeleteTeamModal(false);
      onSwitchTeam(null);
      window.location.reload();
    } catch (error) {
      console.error('Failed to delete team:', error);
      alert('Failed to delete team.');
    }
  };

  const handleQuitTeam = async () => {
    if (!currentTeam) return;

    try {
      await quitTeamFromFirebase(currentTeam, user.uid);
      onSwitchTeam(null);
      window.location.reload();
    } catch (error) {
      console.error('Failed to quit team:', error);
      alert('Failed to quit team.');
    }
  };

  return (
    <div className="flex flex-col items-center w-full">
      {user && <UserIcon userId={user.uid} />}
      
      <header className="bg-white w-full py-4 shadow-md">
        <h1 className="text-xl text-center text-gray-800 font-bold">These are all your transactions.</h1>
      </header>

      <nav className="flex justify-center my-4">
        <button
          className={`mx-2 ${activeTab === 'Transactions' ? 'bg-blue-500 text-white' : 'text-blue-500'}`}
          onClick={() => setActiveTab('Transactions')}
        >
          Transactions
        </button>
        <button
          className={`${activeTab === 'Recap' ? 'bg-blue-500 text-white' : 'text-blue-500'}`}
          onClick={() => setActiveTab('Recap')}
        >
          Recap
        </button>
      </nav>

      {activeTab === 'Transactions' && (
        <>
          <div className="flex items-center">
            {/* Team Selection Dropdown */}
            <TeamSelection teams={teams} currentTeam={currentTeam} onSwitchTeam={onSwitchTeam} />

          <button
            className="ml-2 mb-3 p-2 rounded" // Adjust margin-left and margin-top to create space between dropdown and icon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShowTeamActions(!showTeamActions); // Toggle the visibility of TeamActions
            }}
          >
            <img src={settingIcon} alt="Settings" className="w-6 h-6" />
          </button>
          

        </div>


          {/* TeamActions Panel */}
          {showTeamActions && (
            <div className="w-full max-w-4xl mt-6 p-4 bg-gray-100 rounded shadow-md">
              <TeamActions
                showInviteModal={setShowInviteModal}
                showCreateTeamModal={setShowCreateTeamModal}
                showAcceptInvite={setShowAcceptInvite}
                isOwner={isOwner}
                isLastMember={isLastMember}
                handleQuitTeam={handleQuitTeam}
                showDeleteTeamModal={showDeleteTeamModal}
                setShowDeleteTeamModal={setShowDeleteTeamModal}
                setShowSettings={setShowSettings} // Make sure this function is passed correctly
                showSettings={showSettings} // Pass the state as well
                currentTeam={currentTeam}
              />
            </div>
          )}

          <div className="w-full max-w-4xl mt-6">
            <TransactionListWrapper currentTeam={currentTeam} refreshList={refreshList} />
          </div>
        </>
      )}

      {activeTab === 'Recap' && (
        <>
          <div className="flex flex-wrap justify-center items-center">
            <BarChartComponent teamId={currentTeam} chartKey={`bar-chart-${currentTeam}`} />
          </div>

          <div className="recap-section">
            Recap Refund
            <RefundList balances={refundBalances} />
          </div>
        </>
      )}

<Modals
        showInviteModal={showInviteModal}
        closeInviteModal={() => setShowInviteModal(false)}
        currentTeam={currentTeam}
        showAcceptInvite={showAcceptInvite}
        closeAcceptInvite={() => setShowAcceptInvite(false)}
        user={user}
        showCreateTeamModal={showCreateTeamModal}
        setShowCreateTeamModal={setShowCreateTeamModal}
        handleCreateTeam={handleCreateTeam}
        showTransactionModal={showTransactionModal}
        setShowTransactionModal={setShowTransactionModal}
        handleSaveTransaction={handleSaveTransaction}
        showDeleteTeamModal={showDeleteTeamModal}
        setShowDeleteTeamModal={setShowDeleteTeamModal}
        setShowSettings={setShowSettings} // Make sure this function is passed correctly
        showSettings={showSettings} // Pass the state as well
        handleDeleteTeam={handleDeleteTeam}
      />

      {!loading && user && (
        <button
          className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-full shadow-lg hover:bg-green-700"
          onClick={handleShowTransactionModal}
        >
          +++
        </button>
      )}
  
      {!loading && user && (
        <button className="mt-4 py-2 px-4 border rounded" onClick={handleSignOut}>
          Sign Out
        </button>
      )}
    </div>
  );
};

export default UserContent;
