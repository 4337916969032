import React, { useState } from 'react';
import { addTransactionSetting } from './TransactionModalSettings'; // Make sure to import the necessary functions

const TransactionSettingsPanel = ({ onClose, teamId }) => {
  const [newSettingType, setNewSettingType] = useState('type'); // 'type' or 'category'
  const [newSettingName, setNewSettingName] = useState('');
  const handleAddSetting = async () => {
    if (newSettingName.trim() === '') {
      alert('Please enter a name for the setting.');
      return;
    }
    await addTransactionSetting(teamId, newSettingType, newSettingName);
    setNewSettingName(''); // Reset the input after adding the setting
    // Optionally, you could refresh the settings here or notify the user of success
  };

  return (
    // Backdrop to dim the rest of the page and center the modal
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center"> {/* Ensure the backdrop has a high z-index */}
        <div className="bg-white p-6 rounded-lg shadow-xl z-50"> {/* Modal content also needs a z-index */}

            <div className="settings-panel">
            {/* ... panel layout, styling, etc. ... */}
            <select value={newSettingType} onChange={(e) => setNewSettingType(e.target.value)}>
                <option value="type">Type</option>
                <option value="category">Category</option>
            </select>
            <input
                type="text"
                value={newSettingName}
                onChange={(e) => setNewSettingName(e.target.value)}
                placeholder="Enter new setting name"
            />
             <div className="mb-4">
                <label htmlFor="setting-type" className="block text-sm font-medium text-gray-700">Type</label>
                <select id="setting-type" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                    {/* Options */}
                </select>
                </div>
                
                <div className="mb-4">
                <label htmlFor="setting-name" className="block text-sm font-medium text-gray-700">Enter new setting name</label>
                <input id="setting-name" type="text" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" />
                </div>

                <div className="flex items-center justify-between">
                <button onClick={handleAddSetting} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none">
                    Add Setting
                </button>
                <button onClick={onClose} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none">
                    Close
                </button>
            </div>
        </div>
    </div>
    </div>
  );
};

export default TransactionSettingsPanel;
