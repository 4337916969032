import { db } from './services/firebase'; // Make sure the path to your Firebase config is correct
import { doc, getDoc } from 'firebase/firestore';
import { auth } from './services/firebase'; // Path to your Firebase auth config

// Function to fetch and return the username as a string
export const fetchUsername = async () => {
    const user = auth.currentUser; // Get the current authenticated user

    if (user) {
        try {
            // Replace 'Users' with your collection name if different
            const userDocRef = doc(db, 'Users', user.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                // Access the 'username' field from the document
                const data = userDoc.data();
                return data.username || ''; // Return the username or an empty string if not found
            } else {
                console.log("No such document!");
                return ''; // Return an empty string if the document does not exist
            }
        } catch (error) {
            console.error("Error fetching document:", error);
            return ''; // Return an empty string if there is an error
        }
    }
    return ''; // Return an empty string if the user is not authenticated
};
