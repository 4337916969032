import React from 'react';
import InviteMember from './components/Invitation';
import AcceptInvite from './components/AcceptInvitation';
import CreateTeamModal from './components/CreateTeamModal';
import TransactionModal from './TransactionModal';

const Modals = ({
  showInviteModal, closeInviteModal, currentTeam, showAcceptInvite, closeAcceptInvite, user,
  showCreateTeamModal, setShowCreateTeamModal, handleCreateTeam, showTransactionModal, setShowTransactionModal,
  handleSaveTransaction, showDeleteTeamModal, setShowDeleteTeamModal, handleDeleteTeam, showDeleteTransactionModal, setShowDeleteTransactionModal, handleConfirmDeleteTransaction, transactionToDelete
}) => {
  return (
    <>
      {showInviteModal && (
        <InviteMember
          teamId={currentTeam}
          onClose={closeInviteModal}
        />
      )}

      {showAcceptInvite && (
        <AcceptInvite
          onClose={closeAcceptInvite}
          currentUser={user}
        />
      )}

      {showCreateTeamModal && (
        <CreateTeamModal
          onClose={() => setShowCreateTeamModal(false)}
          onSave={handleCreateTeam}
        />
      )}

      {showTransactionModal && (
        <TransactionModal
          onClose={() => setShowTransactionModal(false)}
          onSave={handleSaveTransaction}
          teamId={currentTeam}
        />
      )}

        {showDeleteTransactionModal && (
            <ConfirmDeleteModal
            isOpen={showDeleteTransactionModal}
            onClose={() => setShowDeleteTransactionModal(false)}
            onConfirm={handleConfirmDeleteTransaction}
            transaction={transactionToDelete}
            />
        )}
      

      {showDeleteTeamModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* Background overlay */}
            <div className="fixed inset-0 transition-opacity" onClick={() => setShowDeleteTeamModal(false)}>
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            {/* Modal Panel */}
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                      Delete Team
                    </h3>
                    <div className="mt-2">
                      <p>Are you sure you want to delete this team?</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-end rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleDeleteTeam}
                >
                  Yes, Delete
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-start rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setShowDeleteTeamModal(false)}
                >
                  Cancel
                </button>
              </div>
              {/* Close Button */}
              <button
                className="absolute top-0 right-0 pt-4 pr-4"
                onClick={() => setShowDeleteTeamModal(false)}
              >
                <span className="text-gray-400 hover:text-gray-500">&times;</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ConfirmDeleteModal = ({ isOpen, onClose, onConfirm, transaction }) => {
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg p-4 max-w-sm mx-auto">
          <div className="text-lg font-bold mb-4">Delete Transaction</div>
          <p>Are you sure you want to delete this transaction?</p>
          <div className="mt-4 flex justify-end">
            
            <button
              onClick={() => onConfirm(transaction)}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-r"
            >
              Yes, Delete
            </button>
            <button
              onClick={onClose}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-l"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };

export default Modals;
