import { db } from './services/firebase'; // assuming your firebase config and services are setup here
import { collection, query, where, getDocs } from 'firebase/firestore';

    // Function to get team information
    export const getTeamInfo = async (teamId) => {
    const teamRef = collection(db, 'Teams');
    const q = query(teamRef, where('id', '==', teamId));
    const querySnapshot = await getDocs(q);
    
    let teamData = {};
    querySnapshot.forEach((doc) => {
      // doc.data() is used to get the data of the document snapshot
      teamData = { id: doc.id, ...doc.data() };
    });
    return teamData;
  };
    // Function to get transactions
    export const getTransactions = async (teamId) => {
    const transactionsRef = collection(db, 'Teams', teamId, 'Transactions');
    const q = query(transactionsRef); // No need for 'where' since we're already querying a subcollection of the specific team
    const querySnapshot = await getDocs(q);
    
    let transactions = [];
    querySnapshot.forEach((doc) => {
      transactions.push({ id: doc.id, ...doc.data() });
    });
    return transactions;
  };
  
  // Function to process chart data
  export const processChartData = (transactions) => {
    // Example processing to get total spend per member
    let totalsPerMember = {};
    transactions.forEach((transaction) => {
      const amount = parseFloat(transaction.amount) || 0;
      const member = transaction.who; // Assuming 'who' field contains the member ID or name
      
      if (totalsPerMember[member]) {
        totalsPerMember[member] += amount;
      } else {
        totalsPerMember[member] = amount;
      }
    });
  
    // Convert the totals to the format required by the chart
    const labels = Object.keys(totalsPerMember);
    const data = Object.values(totalsPerMember);
    
    return {
      labels,
      datasets: [{
        label: 'Total Spend per Member',
        data,
        backgroundColor: 'rgba(54, 162, 235, 0.6)', // Example color
        borderWidth: 1
      }]
    };
  };

  export const processRefundData = (transactions) => {
    let balances = {};
  
    transactions.forEach((transaction) => {
      // Check if reimbursement is needed and has an array of reimbursements
      if (transaction.reimbursementNeeded && Array.isArray(transaction.reimbursements)) {
        transaction.reimbursements.forEach((reimbursement) => {
          const amount = parseFloat(reimbursement.amount) || 0;
          const member = reimbursement.memberId; // The member who should be reimbursed
  
          // Update balance for the member
          balances[member] = (balances[member] || 0) + amount;
        });
      }
    });
  
    return balances; // This will be an object with member names as keys and their total reimbursement as values
  };
  

  
// Note: You will call these functions from your component and manage state/loading there.
