import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, deleteDoc, getDoc, updateDoc, arrayRemove, doc  } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBlK9JGRBJtVJv-LiJivto_ETZP521i8Eo",
    authDomain: "bettercount-4918f.firebaseapp.com",
    projectId: "bettercount-4918f",
    storageBucket: "bettercount-4918f.appspot.com",
    messagingSenderId: "537116336101",
    appId: "1:537116336101:web:188c8eabeaa0846b75590c",
    measurementId: "G-3CQLS55DF8"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

const auth = getAuth(app);

export const deleteTeamFromFirebase = async (teamId, userId) => {
  if (!teamId) {
    throw new Error('No team ID provided');
  }

  console.log(teamId);
  // Reference to the team document
  const teamRef = doc(db, 'Teams', teamId);

  // Reference to the user's document
  const userRef = doc(db, 'Users', userId);

  console.log('Preparing to delete team with ID:', teamId);

  try {
    // Retrieve the team document to get the ownerId
    const teamSnap = await getDoc(teamRef);
    if (!teamSnap.exists()) {
      throw new Error('Team does not exist');
    }

    const teamData = teamSnap.data();
    if (teamData.ownerId !== userId) {
      throw new Error('User is not the owner of the team');
    }

    // Delete the team document
    await deleteDoc(teamRef);
    console.log('Team deleted successfully');

    // Update the user document to remove the team ID from the teams array
    await updateDoc(userRef, {
      teams: arrayRemove(teamId)
    });
    console.log('Team ID removed from user\'s teams array');
    
  } catch (error) {
    console.error('Error during team deletion:', error);
    throw new Error('Failed to delete team: ' + error.message);
  }
};

export const quitTeamFromFirebase = async (teamId, userId) => {
  if (!teamId || !userId) {
    throw new Error('Team ID and User ID are required');
  }

  const teamRef = doc(db, 'Teams', teamId);
  const userRef = doc(db, 'Users', userId);

  console.log('Preparing to remove user from team with ID:', teamId);

  try {
    const teamSnap = await getDoc(teamRef);
    if (!teamSnap.exists()) {
      throw new Error('Team does not exist');
    }

    const teamData = teamSnap.data();

    // Check if the user is the team owner or last member
    if (teamData.ownerId === userId || (teamData.members && teamData.members.length <= 1)) {
      // Here you can choose to delete the team or handle this case differently
      throw new Error('Cannot quit team as owner or last member');
    }

    // Ensure the user is a member of the team
    if (!teamData.members.includes(userId)) {
      throw new Error('User is not a member of the team');
    }

    // Remove the user from the team's members list
    await updateDoc(teamRef, {
      members: arrayRemove(userId)
    });
    console.log('User removed from team\'s members list');

    // Update the user's teams array
    await updateDoc(userRef, {
      teams: arrayRemove(teamId)
    });
    console.log('Team ID removed from user\'s teams array');
    
  } catch (error) {
    console.error('Error during quitting team:', error);
    throw new Error('Failed to quit team: ' + error.message);
  }
};

//console.log('Firebase App initialized:', app);
//console.log('Auth object:', auth);


export { db, auth };
