import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../services/firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import ReCAPTCHA from "react-google-recaptcha";
import Modal from 'react-modal';

Modal.setAppElement('#root');

const AuthForms = ({ onUserChanged }) => {
  // State hooks for form fields and modals
  const [formFields, setFormFields] = useState({
    email: '',
    password: '',
    username: '',
    confirmPassword: '',
    securityQuestion: '',
    securityAnswer: '',
    phoneNumber: '',
    dateOfBirth: '',
    address: '',
    firstName: '',
    lastName: '',
  });
  const [user, setUser] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
      setUser(currentUser);
      currentUser
        ? localStorage.setItem('User', JSON.stringify({ uid: currentUser.uid, email: currentUser.email }))
        : localStorage.removeItem('User');
      onUserChanged(currentUser);
    });
    return unsubscribe;
  }, [onUserChanged]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormFields(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (!validateSignUp()) return;
  
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, formFields.email, formFields.password);
      const user = userCredential.user;
      await setDoc(doc(db, 'Users', user.uid), {
        ...formFields,
        teams : [],
      });
      onUserChanged(user);
    } catch (error) {
      setErrors(prevErrors => ({ ...prevErrors, signUp: error.message }));
    }
  };
  
  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, formFields.email, formFields.password);
      onUserChanged(userCredential.user);
    } catch (error) {
      setErrors(prevErrors => ({ ...prevErrors, signIn: error.message }));
    }
  };
  
  const validateSignUp = () => {
    let isValid = true;
    let errors = {};
  
    if (formFields.password !== formFields.confirmPassword) {
      errors.confirmPassword = "Passwords do not match!";
      isValid = false;
    }
    if (!captchaValue) {
      errors.captchaValue = "Please verify that you are not a robot.";
      isValid = false;
    }
  
    setErrors(errors);
    return isValid;
  };
  
  const handleCaptchaChange = (value) => setCaptchaValue(value);
  const toggleSignUpModal = () => setIsSignUpModalOpen(prev => !prev);

  const formInputs = [
    { name: 'firstName', type: 'text', placeholder: 'First Name' },
    { name: 'lastName', type: 'text', placeholder: 'Last Name' },
    { name: 'username', type: 'text', placeholder: 'Username' },
    { name: 'email', type: 'email', placeholder: 'Email Address' },
    { name: 'phoneNumber', type: 'tel', placeholder: 'Phone Number' },
    { name: 'dateOfBirth', type: 'date', placeholder: 'Date of Birth' },
    { name: 'address', type: 'text', placeholder: 'Address' },
    { name: 'password', type: 'password', placeholder: 'Password' },
    { name: 'confirmPassword', type: 'password', placeholder: 'Confirm Password' },
    { name: 'securityQuestion', type: 'text', placeholder: 'Security Question' },
    { name: 'securityAnswer', type: 'text', placeholder: 'Security Answer' },
  ];
  // Function to close the sign-up modal
  const closeSignUpModal = () => {
    setIsSignUpModalOpen(false); // Corrected the state variable name here
  };

  const handleSignUpModalClose = () => {
    setIsSignUpModalOpen(false);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      // Display success message to the user
      // You can use state to handle this
      console.log("Password reset email sent successfully");
    } catch (error) {
      // Handle errors (e.g., user not found)
      // You can use state to display error messages
      console.error("Error sending password reset email: ", error);
    }
  };
  
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');

  const toggleForgotPasswordModal = () => setForgotPasswordModalOpen(prev => !prev);


  return (
    <div className="auth-forms max-w-md mx-auto">
      {/* Sign In Form */}
      <form onSubmit={handleSignIn} className="sign-in-form bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          name="email"
          type="email"
          placeholder="Email"
          value={formFields.email}
          onChange={handleInputChange}
          required
        />
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-4"
          name="password"
          type="password"
          placeholder="Password"
          value={formFields.password}
          onChange={handleInputChange}
          required
        />
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4" type="submit">Sign In</button>

        <button 
          className="w-full text-center bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
          onClick={toggleForgotPasswordModal}
        >
          Forgot Password?
        </button>

        {errors.signIn && <div className="error-message text-red-500 text-xs italic mt-2">{errors.signIn}</div>}
      </form>

      
      
      <Modal
          isOpen={forgotPasswordModalOpen}
          onRequestClose={toggleForgotPasswordModal}
          contentLabel="Forgot Password"
          className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex" // Centers the modal
          overlayClassName="fixed inset-0 bg-black opacity-50" // Dark overlay
        >
          <div className="relative p-8 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
            <form onSubmit={handleForgotPassword} className="space-y-6">
              <div className="flex items-center justify-between">
                <h3 className="text-xl font-medium">Forgot Password</h3>
                <button
                  type="button"
                  onClick={toggleForgotPasswordModal}
                  className="text-black close-modal"
                >
                  &times;
                </button>
              </div>
              <div>
                <input
                  type="email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="px-4 py-2 w-full border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div className="flex items-center justify-end">
                <button
                  type="submit"
                  className="px-6 py-2 border border-transparent text-white bg-blue-600 hover:bg-blue-700 rounded-md"
                >
                  Send Reset Email
                </button>
              </div>
            </form>
          </div>
        </Modal>


     {/* Styled Sign Up Button */}
    <button 
      className="w-full text-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
      onClick={toggleSignUpModal}
    >
      Sign Up
    </button>
    
        <Modal
            isOpen={isSignUpModalOpen}
            onRequestClose={toggleSignUpModal}
            className="max-w-2xl mx-auto mt-24 overflow-y-auto max-h-full"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            contentLabel="Sign Up"
            >
            <div className="p-6 bg-white rounded-md shadow-xl">
              <h2 className="text-xl font-bold text-gray-900 mb-4">Sign Up</h2>
              <form onSubmit={handleSignUp} className="space-y-4">
                {formInputs.map(input => (
                  <div key={input.name} className="form-control">
                    <label htmlFor={input.name} className="block text-sm font-medium text-gray-700">{input.placeholder}</label>
                    <input
                      id={input.name}
                      name={input.name}
                      type={input.type}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder={input.placeholder}
                      value={formFields[input.name]}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                ))}
                <div className="flex justify-center">
                  <ReCAPTCHA
                    sitekey="6LeaeFspAAAAACnap7YNAZynZsMPR5K68MnTz3tA"
                    onChange={handleCaptchaChange}
                    className="my-4"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <button type="submit" className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    Sign Up
                  </button>
                </div>
                {Object.values(errors).map((error, index) => (
                  <div key={index} className="text-center text-sm font-medium text-red-500">{error}</div>
                ))}
              </form>
            </div>
        </Modal>  


    </div>
  );
  
};

export default AuthForms;
//6LeaeFspAAAAACnap7YNAZynZsMPR5K68MnTz3tA