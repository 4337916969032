// TransactionModalSettings.js
import { db } from './services/firebase';
import { collection, getDocs, doc, getDoc, setDoc, deleteDoc, runTransaction } from 'firebase/firestore';

// Function to get transaction types and categories from Firestore
export const getTransactionSettings = async (teamId) => {
    const transactionSettingsCollectionRef = collection(db, 'Teams', teamId, 'TransactionSettings');
    const settingsSnapshot = await getDocs(transactionSettingsCollectionRef);
  
    // If there are no documents, create one with default settings
    if (settingsSnapshot.empty) {
      const newSettingsDocRef = doc(transactionSettingsCollectionRef, 'settings');
      const defaultSettings = { types: [], categories: [] };
      await setDoc(newSettingsDocRef, defaultSettings);
      return defaultSettings;
    } else {
      // If there's exactly one document, return its data
      if (settingsSnapshot.docs.length === 1) {
        return settingsSnapshot.docs[0].data();
      } else {
        // If there are multiple documents, keep the first and delete the rest
        let settingsData;
        settingsSnapshot.docs.forEach(async (docSnapshot, index) => {
          if (index === 0) {
            settingsData = docSnapshot.data(); // Keep the data from the first document
          } else {
            await deleteDoc(docSnapshot.ref); // Delete all other documents
          }
        });
        return settingsData;
      }
    }
  };
  

// Function to add a new transaction type or category to Firestore
export const addTransactionSetting = async (teamId, settingType, settingName) => {
    const transactionSettingsCollectionRef = collection(db, 'Teams', teamId, 'TransactionSettings');
    // Start a transaction
    await runTransaction(db, async (transaction) => {
      const settingsSnapshot = await getDocs(transactionSettingsCollectionRef);
  
      // Check if there are no documents and create one if necessary
      if (settingsSnapshot.empty) {
        const newSettingsDocRef = doc(transactionSettingsCollectionRef, 'settings'); // Create a doc with ID 'settings'
        const newSettings = { types: [], categories: [] };
        newSettings[settingType] = [settingName];
        transaction.set(newSettingsDocRef, newSettings);
      } else {
        // If there's exactly one document, update it
        if (settingsSnapshot.docs.length === 1) {
          const settingsDocRef = settingsSnapshot.docs[0].ref;
          const data = settingsSnapshot.docs[0].data();
          const updatedSettings = {
            ...data,
            [settingType]: data[settingType] ? [...new Set([...data[settingType], settingName])] : [settingName] // Ensure uniqueness with Set
          };
          transaction.set(settingsDocRef, updatedSettings);
        } else {
          // If there are multiple documents, delete all and create a new one
          settingsSnapshot.docs.forEach((docSnapshot, index) => {
            if (index === 0) {
              // Update the first document with new settings
              const data = docSnapshot.data();
              const updatedSettings = {
                ...data,
                [settingType]: data[settingType] ? [...new Set([...data[settingType], settingName])] : [settingName]
              };
              transaction.set(docSnapshot.ref, updatedSettings);
            } else {
              // Delete all other documents
              transaction.delete(docSnapshot.ref);
            }
          });
        }
      }
    });
  };