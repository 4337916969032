import React, { useState, useEffect } from 'react';
import UserIconImage from './UserIcon.png';
import Modal from 'react-modal';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './services/firebase'; // Ensure this points to your Firebase config file

Modal.setAppElement('#root'); // For accessibility reasons

function UserIcon({ userId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useState(null);

  const fetchUserData = async (userId) => {
    const userDoc = doc(db, 'Users', userId);
    const docSnap = await getDoc(userDoc);
  
    if (docSnap.exists()) {
      return docSnap.data(); // Returns user data
    } else {
      // Handle the case where there is no such document
      console.log('No such document!');
      return null;
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData(userId).then(setUser);
    }
  }, [userId]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  if (!userId) return null; // Or some loading state

  return (
    <div className="absolute top-0 right-0">
      <img 
        src={UserIconImage} 
        alt="Your informations" 
        onClick={toggleModal} 
        className="cursor-pointer w-12 h-auto mr-2" // Tailwind classes
      />
      
      <Modal
        isOpen={isModalOpen}
        onRequestClose={toggleModal}
        className="modal-content max-w-md mx-auto bg-white p-4 rounded shadow-lg outline-none"
        overlayClassName="modal-overlay bg-black bg-opacity-50 fixed inset-0 flex items-center justify-center"
      >
        {user ? (
          <div className="space-y-4">
            <h2 className="text-xl font-bold">User's Information</h2>
            <p><strong>First Name:</strong> {user.firstName}</p>
            <p><strong>Last Name:</strong> {user.lastName}</p>
            <p><strong>Phone Number:</strong> {user.phoneNumber}</p>
            <p><strong>Date of Birth:</strong> {user.dateOfBirth}</p>
            <p><strong>Address:</strong> {user.address}</p>
            <p><strong>Username:</strong> {user.username}</p>
            <p><strong>Email:</strong> {user.email}</p>
            <button onClick={toggleModal} className="mt-4 py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-200">Close</button>
          </div>
        ) : (
          <div className="text-xl font-bold">Loading user information...</div>
        )}
      </Modal>
    </div>
  );
}

export default UserIcon;
