import React, { useState } from 'react';

const CreateTeamModal = ({ onClose, onSave }) => {
  const [teamName, setTeamName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(teamName);
  };

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      {/* Background overlay, show modal backdrop */}
      <div className="fixed inset-0 transition-opacity">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>
      {/* Modal Panel */}
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
              <p className="modal-title">Create Team</p>
              </h3>
              <div className="mt-2">
                <form className="accept-invite-form"></form>
                  <input 
                    type="text" 
                    value={teamName} 
                    onChange={(e) => setTeamName(e.target.value)}
                    placeholder="Team Name" 
                    required 
                  />
            </div>
          </div>
        </div>
      </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button onClick={handleSubmit} 
          className="ml-3 inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-green-600 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-0 sm:w-auto sm:text-sm"
          >Create Team</button>
            </div>
            <button
                className="absolute top-0 right-0 pt-4 pr-4"
                onClick={onClose}
              >
                <span className="text-gray-400 hover:text-gray-500">&times;</span>
              </button>
      </div>
    </div>
    </div>
  );
};

export default CreateTeamModal;
