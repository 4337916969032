import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { getTransactions, processChartData } from './RecapData'; // Import the functions from RecapData.js

const BarChartComponent = ({ teamId, chartKey }) => {
  // State to store processed chart data
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch transactions for the given teamId
        const transactions = await getTransactions(teamId);
        // Process data for the chart
        const processedData = processChartData(transactions);
        // Set the processed data to the state
        setChartData(processedData);
      } catch (error) {
        console.error('Error fetching or processing data:', error);
        // Handle error case, e.g., set an error message state and display it
      }
    };

    fetchData();
  }, [teamId]); // Dependency array, re-run effect if teamId changes

  // Options for the chart
  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
      },
    },
    // ... other options if necessary
  };

  // Render the BarChart component with the data from state
  return (
    <div>
      {chartData.labels ? (
        <Bar key={chartKey} data={chartData} options={options} />
      ) : (
        <p>Loading chart data...</p> // Display a loading message or spinner
      )}
    </div>
  );
};

export default BarChartComponent;
