import { getFirestore, doc, getDoc, setDoc, updateDoc, arrayUnion, collection } from 'firebase/firestore';

const db = getFirestore();

// Function to create a new team
export const createTeam = async (teamName, userId) => {
    try {
        // Create a new team document with a generated ID
        const teamRef = doc(collection(db, 'Teams'));
        await setDoc(teamRef, {
            name: teamName,
            members: [userId], // Initial member is the creator
            ownerId : userId
        });

        //console.log(teamRef)
        //console.log(teamName)
        //console.log(userId)

        // Check if the user's document exists before updating
        const userRef = doc(db, 'Users', userId);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
            // User document exists, update it
            await updateDoc(userRef, {
                teams: arrayUnion(teamRef.id) // Add the team ID to the user's teams array
            });
        } else {
            // User document does not exist, create it
            await setDoc(userRef, {
                teams: [teamRef.id] // Initialize the teams array with the new team ID
            });
        }

        return teamRef.id; // Return the new team ID for further use
    } catch (error) {
        console.error('Error creating team:', error);
        throw error; // Rethrow the error to handle it at a higher level
    }
};

// Function to add a user to a team
export const addUserToTeam = async (userId, teamId) => {
  // Add the user ID to the team's members array
  const teamRef = doc(db, 'Teams', teamId);
  await updateDoc(teamRef, {
    members: arrayUnion(userId) // Add the user ID to the team's members array
  });

  // Add the team ID to the user's teams array
  const userRef = doc(db, 'Users', userId);
  await updateDoc(userRef, {
    teams: arrayUnion(teamId) // Add the team ID to the user's teams array
  });
};

// Function to load user's teams
export const loadUserTeams = async (userId) => {
  try {
    const userRef = doc(db, 'Users', userId);
    const userSnap = await getDoc(userRef);


    
    if (userSnap.exists()) {
      const userTeams = userSnap.data().teams || [];
      const teams = [];
  
      for (const teamId of userTeams) {
        const teamRef = doc(db, 'Teams', teamId);
        const teamSnap = await getDoc(teamRef);

  
        if (teamSnap.exists()) {
          teams.push({ id: teamSnap.id, ...teamSnap.data() });
        }
      }
  
      return teams;
    } else {
      console.log('No such user!');
      return [];
    }
  } catch (error) {
    console.error('Error loading user teams:', error);
    return [];
  }
};


//other team related functions
