import React from 'react';

const RefundList = ({ balances }) => {
  // Find the max absolute balance to set a relative width for bars
  const maxBalance = Math.max(...Object.values(balances).map(Math.abs));

  return (
    <div className="refund-list w-full">
      {Object.entries(balances).map(([member, balance]) => {
        // Calculate width percentage based on the max balance
        const widthPercent = Math.abs(balance) / maxBalance * 100;
        
        return (
          <div key={member} className="my-2">
            <div className="flex items-center justify-between">
              <span className="member-name">{member}</span>
              <span className={`member-balance ${balance >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                {balance.toFixed(2)} $
              </span>
            </div>
            <div className="h-4 bg-gray-200 rounded">
              <div 
                className={`h-4 ${balance >= 0 ? 'bg-green-500' : 'bg-red-500'} rounded`}
                style={{ width: `${widthPercent}%` }}
              ></div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RefundList;
