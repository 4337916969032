import React from 'react';
import TransactionSettingsPanel from './TransactionSettingsPanel'; // Adjust the import path as needed

const TeamActions = ({
  showInviteModal,
  showCreateTeamModal,
  showAcceptInvite,
  isOwner,
  isLastMember,
  handleQuitTeam,
  setShowDeleteTeamModal, // Make sure to pass this prop from the parent component
  setShowSettings, // Function to toggle settings panel visibility
  showSettings, // Boolean state to indicate if settings panel is visible
  currentTeam // Current team ID
}) => {
  return (
    <div className="flex justify-center space-x-2">
      <button
        className="bg-blue-500 text-white py-1 px-2 text-xs rounded hover:bg-blue-700"
        onClick={() => showInviteModal(true)}
      >
        Invite Team Member
      </button>
      <button
        className="bg-blue-500 text-white py-1 px-2 text-xs rounded hover:bg-blue-700"
        onClick={() => showCreateTeamModal(true)}
      >
        Create Team
      </button>
      <button
        className="bg-blue-500 text-white py-1 px-2 text-xs rounded hover:bg-blue-700"
        onClick={() => showAcceptInvite(true)}
      >
        Accept Invitation to Team
      </button>
      {(isOwner || isLastMember) ? (
        <button
          className="bg-red-500 text-white py-1 px-2 text-xs rounded hover:bg-red-700"
          onClick={() => setShowDeleteTeamModal(true)}
        >
          Delete Team
        </button>
      ) : (
        <button
          className="bg-red-500 text-white py-1 px-2 text-xs rounded hover:bg-red-700"
          onClick={() => handleQuitTeam()}
        >
          Quit Team
        </button>
      )}
      
      {/* Conditional rendering of the TransactionSettingsPanel */}
      {showSettings && (
        <TransactionSettingsPanel
          onClose={() => setShowSettings(false)}
          teamId={currentTeam}
        />
      )}

      {/* Team Settings Button */}
      <button
        onClick={() => setShowSettings(true)}
        className="text-sm bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Team Settings
      </button>
    </div>
  );
};

export default TeamActions;
