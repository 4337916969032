import React from 'react';

const TeamSelection = ({ teams, currentTeam, onSwitchTeam }) => {
  return (
    <div className="my-4">
      <select
        className="p-2 border rounded mb-4"
        onChange={(e) => onSwitchTeam(e.target.value)}
        value={currentTeam || ''}
      >
        {teams.map((team) => (
          <option key={team.id} value={team.id}>{team.name}</option>
        ))}
      </select>
    </div>
  );
};

export default TeamSelection;
