import React, { useState, useEffect } from 'react';
import { db } from './services/firebase';
import { collection, onSnapshot, query, orderBy, doc, deleteDoc, updateDoc } from 'firebase/firestore'; 
import { useAuthState } from 'react-firebase-hooks/auth'; // Import useAuthState hook
import { auth } from './services/firebase'; // Adjust the path to your auth file
import './TransactionList.css';
import Modals from './Modals';
import { fetchUsername } from './RetrieveUsername'; // Adjust the path based on your folder structure



function TransactionList({ teamId }) {
    const [transactions, setTransactions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [transactionToDelete, setTransactionToDelete] = useState(null);
    const [showDeleteTransactionModal, setShowDeleteTransactionModal] = useState(false);
    
    const [user] = useAuthState(auth); // Get the current user
    const [username, setUsername] = useState('');

    useEffect(() => {

        const getUsername = async () => {
            const name = await fetchUsername(); // Call the function to get the username
            setUsername(name); // Set the username in state
        };

        getUsername();
        console.log('Current usernameee:', username);

        if (!teamId) {
            console.error('No teamId provided for fetching transactions');
            return;
        }

        const colRef = collection(db, 'Teams', teamId, 'Transactions');
        const q = query(colRef, orderBy("date", "desc"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const transactionsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setTransactions(transactionsData);
        }, error => console.error('Firestore fetch error:', error));

        return () => unsubscribe();
    }, [teamId, user]);

    const handleDelete = (transactionId) => {
        setTransactionToDelete(transactionId);
        setShowDeleteTransactionModal(true);
    };
    
    const handleConfirmDelete = async () => {
        await deleteDoc(doc(db, 'Teams', teamId, 'Transactions', transactionToDelete));
        setTransactionToDelete(null);
        setShowDeleteTransactionModal(false);
    };

    const handleReimbursedChange = async (transactionId, index, currentValue) => {
        try {
            const transactionRef = doc(db, 'Teams', teamId, 'Transactions', transactionId);

            // Update the specific reimbursement entry's reimbursed status
            const updatedReimbursements = transactions.find(t => t.id === transactionId).reimbursements.map((reimbursement, i) => {
                if (i === index) {
                    return { ...reimbursement, reimbursed: !currentValue };
                }
                return reimbursement;
            });

            await updateDoc(transactionRef, { reimbursements: updatedReimbursements });
        } catch (error) {
            console.error('Failed to update reimbursement status:', error);
        }
    };

    function formatDate(dateString) {
        const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
        const dateToFormat = new Date(year, month - 1, day);

        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        today.setHours(0, 0, 0, 0);
        yesterday.setHours(0, 0, 0, 0);

        const isToday = dateToFormat.getTime() === today.getTime();
        const isYesterday = dateToFormat.getTime() === yesterday.getTime();

        if (isToday) {
            return 'Today';
        } else if (isYesterday) {
            return 'Yesterday';
        } else {
            return dateToFormat.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        }
    }

    const calculateOwedAmount = (transaction) => {
        if (!transaction.reimbursementNeeded) return 0;

        const totalReimbursement = transaction.reimbursements.reduce((sum, reimbursement) => {
            return sum + reimbursement.amount;
        }, 0);

        return transaction.amount - totalReimbursement;
    };

    const userOwes = (transaction) => {
        //console.log('user:', user.uid);
        //console.log('memberId:', transaction.reimbursements?.map(reimbursement => reimbursement.memberId));
        return transaction.reimbursements?.some(reimbursement => reimbursement.memberId === username && reimbursement.amount > 0);
    };

    return (
        <div className="transaction-list">
        {transactions.map((transaction) => {
                const owedAmount = calculateOwedAmount(transaction);
                return (
                    
                    <div key={transaction.id} className="transaction-item relative">
                        <div className="transaction-description">{transaction.description}</div>
                        <div className="transaction-amount">{parseFloat(transaction.amount).toFixed(2)} $</div>
                        <div className="transaction-detail">paid by {transaction.who}</div>
                        <div className="transaction-date">{new Date(transaction.date).toLocaleDateString()}</div>

                        {transaction.reimbursements?.map((reimbursement, index) => (
                        <div key={index}>
                            <p>
                                {reimbursement.reimbursed
                                    ? `${reimbursement.memberId === username ? 'You' : reimbursement.memberId} reimbursed ${reimbursement.amount.toFixed(2)}$`
                                    : `${reimbursement.memberId === username ? 'You owe' : `${reimbursement.memberId} owes`} ${reimbursement.amount.toFixed(2)}$`}
                            </p>
                            
                            {/* Reimbursed Checkbox */}
                            {reimbursement.memberId === username && (
                                <div className="reimbursed-checkbox">
                                    <label>
                                        Reimbursed: 
                                        <input 
                                            type="checkbox" 
                                            checked={reimbursement.reimbursed || false}
                                            onChange={() => handleReimbursedChange(transaction.id, index, reimbursement.reimbursed)}
                                        />
                                    </label>
                                </div>
                            )}
                        </div>
                        ))}

                        <button 
                            onClick={() => handleDelete(transaction.id)}
                            className="absolute top-0 right-0 mt-2 mr-2 text-sm bg-red-500 text-white py-1 px-2 rounded"
                        >
                            x
                        </button>
                    </div>
                );
        })}

           <Modals
            showDeleteTransactionModal={showDeleteTransactionModal}
            setShowDeleteTransactionModal={setShowDeleteTransactionModal}
            handleConfirmDeleteTransaction={handleConfirmDelete}
        />
        </div>
    );
}

export default TransactionList;
