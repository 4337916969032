import React, { useState, useEffect } from 'react';
import AuthForms from './components/AuthForms';
import UserContent from './components/UserContent';
import { createTeam, loadUserTeams } from './services/TeamsServices'; // Import team service functions
import './App.css';
import './index.css';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [teams, setTeams] = useState([]); // Array of user's teams
  const [currentTeam, setCurrentTeam] = useState(null); // Currently selected team
  
  // Load teams when a user logs in
  useEffect(() => {
    if (currentUser) {
      loadUserTeams(currentUser.uid).then(setTeams);
    }
  }, [currentUser]);

  // Handle team creation
  const handleCreateTeam = async (teamName) => {
    console.log('current user', currentUser, ' testing this:', teamName);
    if (!currentUser) return;
    const teamId = await createTeam(teamName, currentUser.uid);

    setTeams([...teams, { id: teamId, name: teamName }]);
    setCurrentTeam(teamId); // Set the newly created team as the current teamss
  };

  // Handle team switching
  const handleSwitchTeam = (teamId) => {
    setCurrentTeam(teamId);
  };

  return (
    <div className="App">
      {!currentUser ? (
        <AuthForms onUserChanged={setCurrentUser} />
      ) : (
        <UserContent
          onUserChanged={setCurrentUser}
          teams={teams}
          currentTeam={currentTeam}
          onCreateTeam={handleCreateTeam}
          onSwitchTeam={handleSwitchTeam}
        />
      )}
    </div>
  );
}

export default App;
