import React, { useState, useEffect } from 'react';
import './TransactionModal.css'; // Ensure you have the corresponding CSS file
import { collection, addDoc, getDoc, doc } from 'firebase/firestore';
import { auth, db } from './services/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getTransactionSettings } from './TransactionModalSettings';

const TransactionModal = ({ onClose, onSave, teamId }) => {
  // Options for the dropdown menus (these could also be fetched from an API or defined elsewhere)
  const [whoOptions, setWhoOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState(['Expense', 'Income']);
  const [categoryOptions, setCategoryOptions] = useState(['Food', 'Utilities', 'Salary']);
  const [statusOptions, setStatusOptions] = useState(['Paid', 'Pending', 'Credit']);
  const [types, setTypes] = useState([]);
  const [categories, setCategories] = useState([]);

  const [user] = useAuthState(auth);
  const [tempWho, setTempWho] = useState('');

  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState('');
  const [currency, setCurrency] = useState("CAD"); // Default to USD
  const [who, setWho] = useState(whoOptions[0]); // Default to first option in whoOptions
  const [type, setType] = useState(typeOptions[0]); // Default to first option in typeOptions
  const [category, setCategory] = useState(categoryOptions[0]); // Default to first option in categoryOptions
  const [status, setStatus] = useState(statusOptions[0]); // Default to first option in statusOptions

  // State to track reimbursement entries for members
  const [reimbursementEntries, setReimbursementEntries] = useState([]);
  const [reimbursement_Needed, setReimbursementNeeded] = useState(false);
  const [reimbursementPercentage, setReimbursementPercentage] = useState('');
  const [reimbursementAmount, setReimbursementAmount] = useState('');
  const [reimbursementComplete, setReimbursementComplete] = useState(false);
  const [reimbursementError, setReimbursementError] = useState('');

  const [errors, setErrors] = useState({
    date: false,
    description: false,
    amount: false,
    who: false,
    type: false,
    category: false,
    status: false,
    reimbursement: false
  });
  
  const handleSave = () => {
    // Perform the validation and update the errors state
    const isValid = validateFields();

    // If the validation fails, prevent the save operation
    if (!isValid) {
        // You can optionally show an alert or set an error message state here
        console.log('Please correct the errors before saving.');
        return;
    }

    if (!user) {
      console.error('User is not authenticated');
      return;
    }

    if (!teamId) {
      console.error('No team ID provided for saving the transaction');
      return;
    }

    // Ensure all amounts are either converted to the appropriate data type or default to null
    const formattedAmount = parseFloat(amount) || 0;
    const formattedReimbursementPercentage = reimbursement_Needed ? parseFloat(reimbursementPercentage) || 0 : null;
    const formattedReimbursementAmount = reimbursement_Needed ? parseFloat(reimbursementAmount) || 0 : null;
  
    
    // Basic validation
    if (!date) {
      alert('Please enter a date.');
      return;
    }
    if (!description.trim()) {
      alert('Please enter a description.');
      return;
    }
    if (!amount || formattedAmount <= 0) {
      alert('Please enter an amount greater than 0.');
      return;
    }
   /*if (!who.trim()) {
      alert('Please select who paid.');
      return;
    }
    if (!type.trim()) {
      alert('Please select the type of transaction.');
      return;
    }
    if (!category.trim()) {
      alert('Please select a category.');
      return;
    }
    if (!status.trim()) {
      alert('Please select the status.');
      return;
    }
    if (reimbursement_Needed && (!reimbursementPercentage || formattedReimbursementPercentage <= 0) && (!reimbursementAmount || formattedReimbursementAmount <= 0)) {
      alert('Please enter either a reimbursement percentage or amount.');
      return;
    }
    if (!currency.trim()) {
        alert('Please select the currency.');
        return;
      }
  */
      console.log("who:", who);
      console.log("whoOptions:", whoOptions);

      const transactionData = {
        date,
        description,
        amount: formattedAmount,
        who,
        type,
        category,
        status,
        reimbursementNeeded: reimbursement_Needed,
        reimbursementPercentage: formattedReimbursementPercentage,
        reimbursementAmount: formattedReimbursementAmount,
        reimbursementComplete,
        currency,
        teamId, // Include the team ID in the transaction data
        reimbursements: reimbursementEntries.map(entry => ({
          memberId: entry.memberId,
          amount: parseFloat(entry.amount) || 0,
          percentage: parseFloat(entry.percentage) || 0,
          reimbursed: entry.reimbursed, 
        })),
      };

      console.log("transaction data: ", transactionData.memberId);

    // Reference to the Transactions subcollection within the specific team's document
    const colRef = collection(db, 'Teams', teamId, 'Transactions');
    // Save the transaction to Firestore using the addDoc function
    addDoc(colRef, transactionData)
        .then(() => {
            console.log('Transaction added successfully!');
            onClose(); // Close the modal after saving
        })
        .catch((error) => {
            console.error('Error adding transaction: ', error);
        });
  };
  
  const validateFields = () => {
  // Convert the input values here within the validateFields function scope
  const formattedAmount = parseFloat(amount) || 0;
  const formattedReimbursementPercentage = reimbursement_Needed ? parseFloat(reimbursementPercentage) || 0 : null;
  const formattedReimbursementAmount = reimbursement_Needed ? parseFloat(reimbursementAmount) || 0 : null;

  const newErrors = {
    date: !date,
    description: !description.trim(),
    amount: !amount || formattedAmount <= 0,
    /*who: !who.trim(),
    type: !type.trim(),
    category: !category.trim(),
    status: !status.trim(),
    reimbursement: reimbursement_Needed && 
                   ((!reimbursementPercentage && reimbursementPercentage !== 0) || formattedReimbursementPercentage <= 0) &&
                   ((!reimbursementAmount && reimbursementAmount !== 0) || formattedReimbursementAmount <= 0),
    currency: !type.trim(),*/
  };

  setErrors(newErrors);

   // Return the validity of the form
  return !Object.values(newErrors).some(Boolean);
  };

  // Use effect to add the class when the modal mounts and remove it when it unmounts
  useEffect(() => {
    const fetchTeamMembers = async () => {
      try {
        const teamDocRef = doc(db, 'Teams', teamId);
        const teamDocSnapshot = await getDoc(teamDocRef);
        if (teamDocSnapshot.exists()) {
          // Get memberIds array from the team document
          const memberIds = teamDocSnapshot.data().members;
          let memberUsernames = [];
    
          // Check if the authenticated user's ID is in the memberIds and place it first
          if (user && memberIds.includes(user.uid)) {
            const userDocRef = doc(db, 'Users', user.uid);
            const userDocSnapshot = await getDoc(userDocRef);
            if (userDocSnapshot.exists()) {
              // Place the authenticated user's username first in the array
              memberUsernames = [userDocSnapshot.data().username];

            }
          }
       
        
        // Fetch each member's username from the Users collection
        for (const memberId of memberIds) {
          if (user && memberId === user.uid) {
            // Skip the authenticated user as we've already added them
            continue;
          }
          const userDocRef = doc(db, 'Users', memberId);
          const userDocSnapshot = await getDoc(userDocRef);
          if (userDocSnapshot.exists()) {
            // Append other team members' usernames to the array
            memberUsernames.push(userDocSnapshot.data().username);
          }
        }

        // Update state with the usernames, authenticated user's username first
        setWhoOptions(memberUsernames);
        // Set the initial value for 'who' if the memberUsernames array is not empty
        if (memberUsernames.length > 0) {
          setWho(memberUsernames[0]);
        }
      }
    }
      catch (error) {
        console.error('Error fetching team members:', error);
      }
    };

     // Fetch transaction settings
     const fetchSettings = async () => {
      try {
        const settings = await getTransactionSettings(teamId);
    
        // Check if the fetched settings object contains the types and categories as expected
        if (settings && Array.isArray(settings.type) && Array.isArray(settings.category)) {
    
          setTypes(settings.type); // Assuming 'type' is an array of strings
          setCategories(settings.category); // Assuming 'category' is an array of strings
        }
      } catch (error) {
        console.error('Error fetching transaction settings:', error);
      }
    };
    
    

    fetchSettings();

    fetchTeamMembers();
  }, [teamId, user]); // Include 'user' in the dependency array
  
  // Function to handle adding a temporary 'who' option
  const handleAddTemporaryWhoOption = () => {
    if (tempWho.trim() !== '') {
      setWhoOptions(prevWhoOptions => [...prevWhoOptions, tempWho]);
      setWho(tempWho); // Set the new temporary 'who' as the selected 'who'
      setTempWho(''); // Reset the temporary 'who' input
    }
  };

  // Function to handle changes in the reimbursement amount
  const handleReimbursementMemberChange = (index, value) => {
    const updatedEntries = [...reimbursementEntries];
    updatedEntries[index].memberId = value;
    setReimbursementEntries(updatedEntries);
    console.log("Updated reimbursementEntries:", updatedEntries);  // Debugging to see the updates
  };

  // Function to handle changes in the reimbursement amount
  const handleReimbursementAmountChange = (index, value) => {
    // Clear any existing reimbursement errors
    setReimbursementError('');

    const updatedEntries = [...reimbursementEntries];
    const newAmount = parseFloat(value) || 0;
  
    // Calculate total reimbursement with the new amount
    const totalReimbursement = updatedEntries.reduce((acc, curr, i) => {
      return acc + (i === index ? newAmount : curr.amount);
    }, 0);
  
    // Check if the total reimbursement exceeds the transaction amount
    if (totalReimbursement <= parseFloat(amount)) {
      updatedEntries[index].amount = newAmount;
      updatedEntries[index].percentage = (newAmount / parseFloat(amount)) * 100;
      setReimbursementEntries(updatedEntries);
    } else {
      // Set the error message
      setReimbursementError('Total reimbursement cannot exceed the transaction amount.');
    }
  };

  // Function to handle changes in the reimbursement percentage
  const handleReimbursementPercentageChange = (index, value) => {
    const updatedEntries = [...reimbursementEntries];
    const newPercentage = parseFloat(value) || 0;
    const newAmount = (parseFloat(amount) * newPercentage) / 100;
  
    // Calculate total reimbursement with the new percentage
    const totalReimbursement = updatedEntries.reduce((acc, curr, i) => {
      return acc + (i === index ? newAmount : curr.amount);
    }, 0);
  
    // Check if the total reimbursement exceeds the transaction amount
    if (totalReimbursement <= parseFloat(amount)) {
      updatedEntries[index].percentage = newPercentage;
      updatedEntries[index].amount = newAmount;
      setReimbursementEntries(updatedEntries);
    } else {
      // Handle the error case, e.g., by showing a message or resetting the value
      console.error('Total reimbursement cannot exceed the transaction amount.');
    }
  };

  // Function to handle changes in the reimbursed status
  const handleReimbursementStatusChange = (index, value) => {
    const updatedEntries = [...reimbursementEntries];
    updatedEntries[index].reimbursed = value;
    setReimbursementEntries(updatedEntries);
  };

  const handleAddReimbursementEntry = () => {
    setReimbursementEntries([...reimbursementEntries, { memberId: '', amount: '', percentage: '', reimbursed: false }]);
  };

  return (
    <div className="modal-overlay" onClick={(e) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    }}>
      <div className="modal-content relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
      <div className="modal-header flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold text-center flex-1">Add New Transaction</h2>
        <button className="close text-2xl" onClick={onClose}>&times;</button>
      </div>
      <form onSubmit={(e) => e.preventDefault()} className="modal-body space-y-4">
        <div className="form-group">
          <label htmlFor="date" className="form-label inline-block mb-2 text-gray-700">Date</label>
          <input
            type="date"
            id="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className={`form-input ${errors.date ? 'input-error' : ''}`}
          />
          {errors.date && <p className="error-message text-red-500 text-xs italic">Date is required.</p>}

        {/* Input for adding temporary 'who' option */}
        <div className="form-group">
          <label htmlFor="tempWho" className="form-label inline-block mb-2 text-gray-700">Temporary Who</label>
          <input
            type="text"
            id="tempWho"
            value={tempWho}
            onChange={(e) => setTempWho(e.target.value)}
            placeholder="Add Temporary Who"
            className="form-input"
          />
          <button 
            onClick={handleAddTemporaryWhoOption}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none"
            type="button" // Specify button type to prevent form submission
          >
            Add
          </button>
        </div>

  
        <div className="form-group">
          <label htmlFor="description" className="form-label inline-block mb-2 text-gray-700">Title</label>
          <input
            type="text"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className={`form-input ${errors.description ? 'border-red-500' : 'border-gray-300'}`}
            placeholder="Description"
          />
          {errors.description && <p className="error-message text-red-500 text-xs italic">Description is required.</p>}
        </div>

        <div className="form-group">
          <label htmlFor="amount" className="form-label inline-block mb-2 text-gray-700">Amount</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className={`form-input ${errors.amount ? 'border-red-500' : 'border-gray-300'}`}
            placeholder="Amount"
          />
          {errors.amount && <p className="error-message text-red-500 text-xs italic">Amount is required and must be greater than 0.</p>}
        </div>

        <div className="form-group">
          <label htmlFor="currency" className="form-label inline-block mb-2 text-gray-700">Currency</label>
          <select 
            id="currency" 
            value={currency} 
            onChange={(e) => setCurrency(e.target.value)}
            className="form-select block w-full mt-1 border-gray-300 focus:border-indigo-500 rounded-md shadow-sm"
          >
            <option value="CAD">CAD</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            <option value="GBP">GBP</option>
            {/* Add more currencies as needed */}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="who" className="form-label inline-block mb-2 text-gray-700">Paid by</label>
          <select
            id="who"
            value={who}
            onChange={(e) => setWho(e.target.value)}
            className={`form-select block w-full mt-1 ${errors.who ? 'border-red-500' : 'border-gray-300'} focus:border-indigo-500 rounded-md shadow-sm`}
          >
            {whoOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          {errors.who && <p className="error-message text-red-500 text-xs italic">Please select who paid.</p>}
        </div>

        <div className="form-group">
          <label htmlFor="type" className="form-label inline-block mb-2 text-gray-700">Type</label>
          <select
            id="type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            className={`form-select block w-full mt-1 ${errors.type ? 'border-red-500' : 'border-gray-300'} focus:border-indigo-500 rounded-md shadow-sm`}
          >
            {types.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          {errors.type && <p className="error-message text-red-500 text-xs italic">Please select the type of transaction.</p>}
        </div>

        <div className="form-group">
          <label htmlFor="category" className="form-label inline-block mb-2 text-gray-700">Category</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className={`form-select block w-full mt-1 ${errors.category ? 'border-red-500' : 'border-gray-300'} focus:border-indigo-500 rounded-md shadow-sm`}
          >
            {categories.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          {errors.category && <p className="error-message text-red-500 text-xs italic">Please select a category.</p>}
        </div>


        <div className="form-group">
          <label htmlFor="status" className="form-label inline-block mb-2 text-gray-700">Status</label>
          <select
            id="status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            className={`form-select block w-full mt-1 ${errors.status ? 'border-red-500' : 'border-gray-300'} focus:border-indigo-500 rounded-md shadow-sm`}
          >
            {statusOptions.map((option, index) => (
              <option key={index} value={option}>{option}</option>
            ))}
          </select>
          {errors.status && <p className="error-message text-red-500 text-xs italic">Please select the status.</p>}
        </div>
                {/* Display reimbursement error if there is one */}
      {reimbursementError && (
        <p className="text-red-500 text-xs italic">{reimbursementError}</p>
      )}
        {/* Reimbursement Needed Checkbox */}
      <div className="form-group flex items-center">
        <label htmlFor="reimbursementNeeded" className="form-label inline-flex items-center mr-2 text-gray-700">
          Reimbursement needed:
        </label>
        <input
          type="checkbox"
          id="reimbursementNeeded"
          checked={reimbursement_Needed}
          onChange={(e) => setReimbursementNeeded(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-blue-600 ${errors.reimbursement ? 'border-red-500' : 'border-gray-300'}`}
        />
      </div>
      {errors.reimbursement && <p className="error-message text-red-500 text-xs italic">Reimbursement details are required.</p>}

         {reimbursement_Needed && (
        <div>
          {reimbursementEntries.map((entry, index) => (
          <div key={index} className="flex space-x-2 items-center">
            <select
              value={entry.memberId}
              onChange={(e) => handleReimbursementMemberChange(index, e.target.value)}
              className="form-select block w-full mt-1 border-gray-300 focus:border-indigo-500 rounded-md shadow-sm"
            >
              {whoOptions.map((option, whoIndex) => (
                <option key={whoIndex} value={option}>
                  {option}
                </option>
              ))}
              {/* Include temporary 'who' if available */}
              {tempWho && <option value={tempWho}>{tempWho}</option>}
            </select>
            <input
              type="number"
              placeholder="Amount"
              value={entry.amount}
              onChange={(e) => handleReimbursementAmountChange(index, e.target.value)}
              className="form-input block w-20 mt-1 border-gray-300 focus:border-indigo-500 rounded-md shadow-sm"
            />
            <input
              type="number"
              placeholder="Percentage"
              value={entry.percentage}
              onChange={(e) => handleReimbursementPercentageChange(index, e.target.value)}
              className="form-input block w-20 mt-1 border-gray-300 focus:border-indigo-500 rounded-md shadow-sm"
            />
             {/* Reimbursed Checkbox */}
             <input
                type="checkbox"
                checked={entry.reimbursed}
                onChange={(e) => handleReimbursementStatusChange(index, e.target.checked)}
                className="form-checkbox h-5 w-5 text-blue-600"
            />
            <button
              onClick={() => setReimbursementEntries(reimbursementEntries.filter((_, i) => i !== index))}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 focus:outline-none"
            >
              Remove
            </button>
          </div>
        ))}
        <button 
            type="button"
            onClick={handleAddReimbursementEntry}
            className="mt-2 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 focus:outline-none"
          >
            Add Reimbursement Entry
          </button>
        </div>
      )}
  
          <div className="modal-footer mt-4">
          <button
            type="button"
            onClick={handleSave}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none"
          >
            Save Transaction
          </button>
        </div>
        </div>
        </form>
      </div>
    </div>
  );

}
  

export default TransactionModal;
