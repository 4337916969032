import { db } from './services/firebase'; // Adjust the import path as needed

export async function submitTransaction(transactionData) {
  try {
      // Add the transaction to the Firestore collection
      const docRef = await db.collection('Transactions').add(transactionData);
      
      // Optionally, return the document reference for further processing (like getting the ID)
      return docRef;
  } catch (error) {
      console.error('Error adding transaction to Firestore:', error);
      throw error; // Rethrow the error if you want to handle it in the calling component
  }
}
  